<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <!-- 인허가 검토정보 -->
          <c-card class="cardClassDetailForm" title="LBL0010300">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 요청접수 -->
                <c-btn
                  v-if="editable && data.requestStepCd == 'RSC0000002' && isReviewUser"
                  label="LBL0010301"
                  icon="save"
                  @btnClicked="saveData('RSC0000003')"/>
                <!-- 전체 검토완료 -->
                <c-btn
                  v-if="editable && data.requestStepCd == 'RSC0000003' && isReviewUser"
                  label="LBL0010302"
                  icon="save"
                  @btnClicked="saveData('RSC1000000')"/>
                <!-- 기각 -->
                <c-btn
                  v-if="editable && data.requestStepCd == 'RSC0000002' && isReviewUser"
                  label="LBL0010303"
                  icon="delete"
                  @btnClicked="saveData('RSC0999999')"/>
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <!-- 진행단계 -->
                <c-select
                  :disabled="true"
                  codeGroupCd="LICENSE_REQUEST_STEP_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="requestStepCd"
                  label="LBLSTEPPROGRESS"
                  v-model="data.requestStepCd"
                ></c-select>
              </div>
              <div class="col-9">
                <!-- 기각사유 -->
                <c-text
                  :editable="editable"
                  label="LBL0010291"
                  name="dismissReason"
                  v-model="data.dismissReason">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <!-- 인허가 검토 목록 -->
        <c-table
          ref="table"
          title="LBL0010292"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          gridHeight="428px"
        ></c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <q-form ref="editForm2">
          <!-- 인허가 검토 상세 -->
          <c-card title="LBL0010293" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 등록 -->
                <c-btn v-if="editable && isReviewUser && (data.requestStepCd != 'RSC0999999' && data.requestStepCd != 'RSC1000000')" label="LBLREG" icon="add" @btnClicked="addReview" />
                <!-- 삭제 -->
                <c-btn
                  v-if="editable && deleteable && isReviewUser && (data.requestStepCd != 'RSC0999999' && data.requestStepCd != 'RSC1000000')"
                  label="LBLREMOVE"
                  icon="delete_forever"
                  @btnClicked="deleteReview"/>
                <!-- 저장 -->
                <c-btn
                  v-if="editable && saveable && (isReqReviewUser || isReviewUser) && (data.requestStepCd != 'RSC0999999' && data.requestStepCd != 'RSC1000000')"
                  :url="saveReviewUrl"
                  :isSubmit="isReviewSave"
                  :param="dataReview"
                  :mappingType="saveReviewType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveReview"
                  @btnCallback="saveReviewCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <!-- 관련법규 -->
                <c-text
                  :required="true"
                  :editable="false"
                  label="LBL0010245"
                  name="relatedLawsName"
                  v-model="dataReview.relatedLawsName">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 인허가종류 -->
                <c-text
                  :required="true"
                  :afterIcon="editable ? [
                    { name: 'search', click: true, callbackName: 'searchLicenseKindId', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeLicenseKindId', color: 'red' }
                  ] : null"
                  :editable="editable"
                  :readonly="true"
                  label="LBL0010249"
                  name="limLicenseKindName"
                  v-model="dataReview.limLicenseKindName"
                  @searchLicenseKindId="searchLicenseKindId"
                  @removeLicenseKindId="removeLicenseKindId">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 검토담당부서 & 검토담당자 -->
                <c-field
                  :required="true"
                  :editable="editable"
                  :data="dataReview"
                  deptValue="reviewDeptCd"
                  type="dept_user"
                  label="LBL0010293"
                  name="reviewUserId"
                  v-model="dataReview.reviewUserId">
                </c-field>
              </div>
              <div class="col-6">
                <!-- 검토상태 -->
                <c-select
                  codeGroupCd="LIM_LICENSE_KIND_STAUS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="limLicenseKindStatusCd"
                  label="LBL0010278"
                  v-model="dataReview.limLicenseKindStatusCd"
                ></c-select>
              </div>
              <div class="col-12">
                <!-- 인허가 검토 의견 -->
                <c-textarea
                  label="LBL0010304"
                  :editable="editable"
                  name="reviewOpinion"
                  :rows="6"
                  v-model="dataReview.reviewOpinion" />
              </div>
              <div class="col-6">
                <!-- 갱신 여부 -->
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="LBL0010295"
                  name="renewalFlag"
                  v-model="dataReview.renewalFlag"
                />
              </div>
              <div class="col-6">
                <!-- 갱신대상 인허가 -->
                <c-text
                  v-if="dataReview.renewalFlag == 'Y'"
                  :required="dataReview.renewalFlag == 'Y'"
                  :afterIcon="editable ? [
                    { name: 'search', click: true, callbackName: 'searchLicenseId', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeLicenseId', color: 'red' }
                  ] : null"
                  :editable="editable"
                  :readonly="true"
                  label="LBL0010296"
                  name="asLicenseSeqName"
                  v-model="dataReview.asLicenseSeqName"
                  @searchLicenseId="searchLicenseId"
                  @removeLicenseId="removeLicenseId">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "license-request-status",
  props: {
    param: {
      type: Object,
      default: () => ({
        limLicenseRequestId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            // 관련법규
            label: 'LBL0010245',
            align: 'center',
            sortable: true,
          },
          {
            name: 'limLicenseKindName',
            field: 'limLicenseKindName',
            // 인허가종류
            label: 'LBL0010249',
            align: 'center',
            sortable: true,
          },
          {
            name: 'reviewDeptName',
            field: 'reviewDeptName',
            // 검토부서
            label: 'LBL0010273',
            align: 'center',
            sortable: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            // 검토자
            label: 'LBLREVIEWER',
            align: 'center',
            sortable: true,
          },
          {
            name: 'limLicenseKindStatusName',
            field: 'limLicenseKindStatusName',
            // 검토상태
            label: 'LBL0010278',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        limLicenseRequestId: '',  // 인허가 요청 일련번호
        plantCd: '',  // 사업장 코드
        licenseRequestName: '',  // 인허가 요청명
        requestDeptCd: '',  // 요청부서 코드
        requestUserId: '',  // 요청자
        completeRequestDate: '',  // 인허가 완료 요청일
        requestContent: '',  // 요청 내용
        sopMocId: '',  // MOC 일련번호
        requestStepCd: 'RSC0000001',  // 요청및검토 진행 단계 : RSC0000002(요청),RSC0000003(검토 및 진행중),RSC0999999(기각),RSC1000000(완료)
        dismissReason: '',  // 기각사유
        reviewOpinion: '',  // 검토 종합 의견
        reviewUserId: '',  // 종합 검토자
        reviewDeptCd: '',  // 검토 부서
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      },
      dataReview: {
        limLicenseReviewId: '',  // 인허가 검토 일련번호
        limLicenseRequestId: '',  // 인허가 요청 일련번호
        limLicenseKindId: '',  // 인허가종류 알련번호
        limLicenseKindName: '',
        relatedLawsName: '',
        renewalFlag: 'N',  // 갱신 여부, 갱신인 경우는 기존인허가번호 입력
        reviewDeptCd: '',  // 검토부서
        reviewUserId: '',  // 검토자
        reviewOpinion: '',  // 인허가 검토 의견
        reviewCompleteFlag: 'N',  // 검토완료 여부
        limLicenseKindStatusCd: null,  // 인허가종류별 검토상태
        asLicenseSeq: '',  // 기존 인허가 일련번호
        asLicenseSeqName: '',  // 기존 인허가명
        newLicenseSeq: '',  // 등록 인허가 일련번호
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      },
      mainSaveUrl: transactionConfig.sop.lim.lincense.request.update.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      isNew: false,
      updateMode: false,
      isReviewSave: false,
      detailReviewUrl: '',
      saveReviewUrl: transactionConfig.sop.lim.lincense.review.insert.url,
      saveReviewType: 'POST',
      isReviewUser: false,
      isReqReviewUser: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.lim.lincense.request.get.url;
      this.mainSaveUrl = transactionConfig.sop.lim.lincense.request.update.url;
      this.listUrl = selectConfig.sop.lim.lincense.review.list.url;
      this.detailReviewUrl = selectConfig.sop.lim.lincense.review.get.url;
      this.insertUrl = transactionConfig.sop.lim.lincense.review.insert.url;
      this.updateUrl = transactionConfig.sop.lim.lincense.review.update.url;
      this.deleteUrl = transactionConfig.sop.lim.lincense.review.delete.url;
      this.getData();
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getReviewList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailReviewUrl, row.limLicenseReviewId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.dataReview = _result.data;
        this.saveReviewUrl = this.updateUrl;
        this.saveReviewType = 'PUT';
        // 검토자/부서만 저장 가능
        if (_result.data.reviewUserId == this.$store.getters.user.userId || _result.data.reviewDeptCd == this.$store.getters.user.deptCd) {
          this.isReqReviewUser = true;
        } else {
          this.isReqReviewUser = false;
        }
      },
      () => {
      });
    },
    addReview() {
      this.saveable = true;
      this.deleteable = false;
      this.saveReviewUrl = this.insertUrl;
      this.saveReviewType = 'POST';
      this.updateMode = false;
      this.dataReview = {
        limLicenseReviewId: '',  // 인허가 검토 일련번호
        limLicenseRequestId: this.param.limLicenseRequestId,  // 인허가 요청 일련번호
        limLicenseKindId: '',  // 인허가종류 알련번호
        limLicenseKindName: '',
        relatedLawsName: '',
        renewalFlag: 'N',  // 갱신 여부, 갱신인 경우는 기존인허가번호 입력
        reviewDeptCd: '',  // 검토부서
        reviewUserId: '',  // 검토자
        reviewOpinion: '',  // 인허가 검토 의견
        reviewCompleteFlag: 'N',  // 검토완료 여부
        limLicenseKindStatusCd: 'LLKSC00001',  // 인허가종류별 검토상태
        asLicenseSeq: '',  // 기존 인허가 일련번호
        asLicenseSeqName: '',  // 기존 인허가명
        newLicenseSeq: '',  // 등록 인허가 일련번호
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataReview = {
        limLicenseReviewId: '',  // 인허가 검토 일련번호
        limLicenseRequestId: this.param.limLicenseRequestId,  // 인허가 요청 일련번호
        limLicenseKindId: '',  // 인허가종류 알련번호
        limLicenseKindName: '',
        relatedLawsName: '',
        renewalFlag: 'N',  // 갱신 여부, 갱신인 경우는 기존인허가번호 입력
        reviewDeptCd: '',  // 검토부서
        reviewUserId: '',  // 검토자
        reviewOpinion: '',  // 인허가 검토 의견
        reviewCompleteFlag: 'N',  // 검토완료 여부
        limLicenseKindStatusCd: null,  // 인허가종류별 검토상태
        asLicenseSeq: '',  // 기존 인허가 일련번호
        asLicenseSeqName: '',  // 기존 인허가명
        newLicenseSeq: '',  // 등록 인허가 일련번호
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      };
    },
    saveReview() {
      this.$refs['editForm2'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.dataReview.regUserId = this.$store.getters.user.userId;
              this.dataReview.chgUserId = this.$store.getters.user.userId;
              if (this.dataReview.renewalFlag == 'N') {
                this.dataReview.asLicenseSeq = '';
                this.dataReview.asLicenseSeqName = '';
              }
              this.isReviewSave = !this.isReviewSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteReview() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.dataReview.limLicenseReviewId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {limLicenseRequestId: this.param.limLicenseRequestId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    getData() {
      if (this.param.limLicenseRequestId) {
        this.$http.url = this.$format(this.detailUrl, this.param.limLicenseRequestId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          // 요청자나 요청부서원일 경우 버튼실행 가능
          if (this.data.reviewUserId == this.$store.getters.user.userId || this.data.reviewDeptCd == this.$store.getters.user.deptCd) {
            this.isReviewUser = true;
          }
        },);
      } else {
        this.isReviewUser = true;
      }
    },
    saveData(_steoCd) {
      let saveMsg = 'MSGSAVE';
      if (_steoCd == 'RSC0000003') { // 요청접수
        saveMsg = 'MSG0010020'; // 요청에 대하여 [접수처리] 하시겠습니까?
      } else if (_steoCd == 'RSC1000000') { // 전체 검토완료
        saveMsg = 'MSG0010021'; // 인허가종류별 검토가 모두 완료처리됩니다.\n\r검토완료 하시겠습니까?
      } else if (_steoCd == 'RSC0999999') { // 기각
        saveMsg = 'MSG0010022'; // 요청에 대하여 [기각처리] 하시겠습니까?
        if (this.data.dismissReason == '' || this.data.dismissReason === null) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message:
            'MSG0010023',   //기각사유를 입력하십시요.
            type: 'warning', // success / info / warning / error
          });
          return false;
        }
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'warning', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.requestStepCd = _steoCd;
              this.data.chgUserId = this.$store.getters.user.userId;

              this.$http.url = this.mainSaveUrl;
              this.$http.type = 'PUT';
              this.$http.param = this.data
              this.$http.request(() => {
                this.getData();
                this.getReviewList();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              },);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveReviewCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getData();
        this.getList();
        this.rowClick({ limLicenseRequestId: result.data.limLicenseRequestId })
      } else {
        this.getData();
        this.getReviewList();
      }
    },
    searchLicenseKindId() {
      this.popupOptions.title = 'LBL0010269'; // 관련법규/인허가종류 검색
      this.popupOptions.param = {
        type: 'single',
        chkPlantCd: this.data.plantCd, // 사업장번호를 넘기면 갱신대상인지 신규대상인지 확인
      };
      this.popupOptions.target = () => import(`${'@/pages/common/license/licenseKindPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLicenseKindId;
    },
    closeLicenseKindId(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.dataReview.relatedLawsName = data[0].relatedLawsName;
        this.dataReview.limLicenseKindId = data[0].limLicenseKindId;
        this.dataReview.limLicenseKindName = data[0].limLicenseKindName;
        this.dataReview.renewalFlag = data[0].renewalFlag;
        this.dataReview.asLicenseSeq = data[0].asLicenseSeq;
        this.dataReview.asLicenseSeqName = data[0].asLicenseSeqName;
      }
    },
    removeLicenseKindId() {
      this.dataReview.relatedLawsName = '';
      this.dataReview.limLicenseKindId = '';
      this.dataReview.limLicenseKindName = '';
    },
    searchLicenseId() {
      this.popupOptions.title = 'LBL0010297'; // 인허가현황 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/license/licensePop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLicenseId;
    },
    closeLicenseId(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.dataReview.asLicenseSeqName = data[0].limLicenseKindName;
        this.dataReview.asLicenseSeq = data[0].limLicenseKindId;
      }
    },
    removeLicenseId() {
      this.dataReview.asLicenseSeq = '';
      this.dataReview.asLicenseSeqName = '';
    },
  }
};
</script>
